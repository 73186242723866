@import '../layout/Responsive.scss';
@import '../layout/Variables.scss';

#about {
    position: relative;
    z-index: 1;
    background-color: $backgroundColor;
    padding: 6rem 0 10rem 0;

    @include size_S {
        padding: 3rem 1rem 6rem 1rem;
    }

    @include size_XS {
        padding: 3rem 0 6rem 0;
    }

    .about_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-gap: 1rem;
        gap: 2rem;

        @include size_M {
            flex-direction: column!important;
            padding: 0 1rem;
            gap: 4rem;
        }
    
        .about_row_text_holder {
            width: 55%;
    
            @include size_XL {
                width: 50%;
            }
            @include size_M {
                width: 100%;
            }
        }
        .about_row_image_holder{
            width: 45%;
    
            @include size_XL {
                width: 50%;
            }
            @include size_M {
                width: 100%;
            }
        }
    
        .about_row_image_holder {
            background: #ffffff08;
            border-radius: 1000px 0 0 1000px;
            padding: 6rem;

            @include size_M {
                background: transparent;
                padding: 0;
                display: flex;
                max-width: 500px;
            }
    
            img {
                width: 100%;

                @include size_M {
                    max-width: 500px;
                    margin: auto;
                }
            }
        }

        .about_row_text_holder {
            padding-left: 10rem;

            @include size_XL {
                padding-left: 6rem;
            }
            @include size_M {
                padding: 0;
                text-align: center;
            }

            .about_row_title {
                font-size: 4rem;
                font-weight: 600;
                margin-bottom: 1rem;

                @include size_L {
                    font-size: 3rem;
                }
                @include size_XS {
                    font-size: 2rem;
                }
            }
            .about_row_subtitle {
                max-width: 600px;
                font-size: 1.2rem;
                color: #ffffff5e;
                line-height: 1.4;

                @include size_M {
                    margin: auto;
                }

                .highlight_text{
                    margin-top: 1rem;
                    font-weight: bold;
                    display: block;
                }
            }
        }
    }
}