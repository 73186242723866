@import '../layout/Responsive.scss';
@import '../layout/Variables.scss';

#forBeginnersAndProfessionals{
    margin-top: 20vh;

    @include size_L {
        margin-top: 5rem;
    }
    @include size_S {
        margin-top: 2rem;
    }
}
.text_image_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10rem;
    gap: 1rem;

    @include size_M {
        flex-direction: column!important;
        padding: 0 1rem;
        margin-bottom: 4rem;
    }

    .text_image_text_holder {
        width: 50%;

        @include size_M {
            width: 100%;
        }
    }
    .text_image_image_holder{
        width: 50%;

        @include size_M {
            width: 100%;
        }
    }

    .text_image_image_holder {
        display: flex;

        img {
            width: 100%;

            @include size_M {
                max-width: 500px;
                margin: auto;
            }
        }
    }

    .text_image_text_holder {
        display: flex;
        flex-direction: column;

        .text_image_title {
            font-size: 4rem;
            font-weight: 600;
            margin-bottom: 1rem;

            @include size_L {
                font-size: 3rem;
            }
            @include size_XS {
                font-size: 2rem;
            }
        }
        .text_image_subtitle {
            max-width: 600px;
            font-size: 1.2rem;
            color: #ffffff5e;
            line-height: 1.4;
        }
    }

    &:nth-of-type(2n + 1) {
        // Image is on the right size

        .text_image_text_holder {
            padding-left: 14rem;

            @include size_XL {
                padding-left: 7rem;
            }
            @include size_L {
                padding-left: 2rem;
            }
            @include size_M {
                padding-left: 0;
                align-items: center;
                text-align: center;
            }
        }
        img {
            border-radius: 1000px 0 0 1000px;
            @include size_M {
                border-radius: 20px;
            }
        }
    }

    &:nth-of-type(2n) {
        flex-direction: row-reverse;

        .text_image_text_holder {
            padding-right: 15rem;
            text-align: right;
            align-items: flex-end;

            @include size_XL {
                padding-right: 7rem;
            }
            @include size_L {
                padding-right: 2rem;
            }
            @include size_M {
                padding-right: 0;
                align-items: center;
                text-align: center;
            }
        }
        img {
            border-radius: 0 1000px 1000px 0;
            @include size_M {
                border-radius: 20px;
            }
        }
    }
}