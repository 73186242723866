@import '../layout/Responsive.scss';
@import '../layout/Variables.scss';

#contactUs {
    background: $backgroundColorDark;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 15rem;

    @include size_M {
        padding-bottom: 10rem;
    }
    @include size_S {
        padding: 0 1rem;
        padding-bottom: 8rem;
    }

    .contact_us_logo {
        margin-bottom: 3rem;

        img {
            width: 50px;
        }
    }

    .contact_us_title {
        font-size: 3rem;
        font-weight: 600;
        max-width: 600px;
        margin-bottom: 2rem;

        @include size_M {
            font-size: 2rem;
        }
    }
    .contact_us_text{
        font-size: 1.2rem;
        color: #ffffff5e;
        line-height: 1.4;
        max-width: 700px;

        .emoji {
            color: white;
        }
    }

    & .contact_us_form{
        padding: 3rem;
        background-color: $backgroundColor;
        border-radius: 40px;
        width: 100%;
        max-width: 500px;
        margin: 3rem 0.5rem;
        position: relative;

        @include size_L {
            padding: 2.5rem;
        }
        @include size_M {
            padding: 1.5rem;
        }

        & .form_body[data-loading]{
            opacity: 0.2;
            pointer-events: none;
        }

        & .captcha_row{
            text-align: center;
        }

        & .form_row{
            margin: 2rem 0;
            position: relative;

            @include size_L {
                margin: 1.5rem 0;
            }
            @include size_M {
                margin: 1.5rem 0;
            }

            & input, textarea{
                background: #ffffff13;
                border: none;
                color: #d2d2d2;
                display: flex;
                border-radius: 10px;
                width: 100%;
                padding: 0.7rem 1rem;
                font: inherit;
                font-size: 1rem;
                outline: none;
                appearance: none;

                &::placeholder{
                    color: #ffffff36;
                    font-size: 1rem;
                    font:inherit;
                }
            }

            & textarea{
                height: 9rem;
            }
        }

        .form_row[data-error]::after{
            content: attr(data-error);
            position: absolute;
            color: #d22828;
            right: 0;
        }

        .form_row[data-error]{
            & input, & textarea {
                border-color: #d22828;
            }
        }

        & .submit_button{
            background-color: transparent;
            border: 2px solid #48a1b8;
            color: #48a1b8;
            padding: 0.5rem 2rem;
            border-radius: 1000px;
            font-size: 1.25rem;
            cursor: pointer;
            transition: all 0.2s ease;
            line-height: 1;
            font-family: 'Montserrat', sans-serif;

            &:hover{
                background-color: #48a1b8;
                border: 2px solid transparent;
                color: white;
            }
        }
    }
}

.lds_loader {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    position: absolute;
  }
  .lds_loader:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #3d9cb5 transparent #fff transparent;
    animation: lds_loader 1.2s linear infinite;
  }
  @keyframes lds_loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }