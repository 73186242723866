@import './components/layout/Variables.scss';

:root {
  cursor: default;
  background-color: $backgroundColor;
}

::-webkit-scrollbar {
  width: 10px;
  z-index: -999;
}

::-webkit-scrollbar-track {
  background: #fffcf2;
}

::-webkit-scrollbar-thumb {
  background: rgba(24, 23, 26, 0.753);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(24, 23, 26);
}

a:-webkit-any-link {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

@font-face{
  font-family: 'OracleScript';
  src: url('../src/assets/fonts/OraqleScript\ 400.otf');
}