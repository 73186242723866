.bubble_box{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: 0;
}

.bubble{
    position: absolute;
    bottom:-100px;
    width:40px;
    height: 40px;
    background:#f1f1f1;
    border-radius:50%;
    opacity:0.5;
    animation: rise 10s infinite ease-in;
    z-index: 3;
    opacity: 0.2;
  }
  .bubble:nth-child(even){
    background: #48a1b8;
  }
  .bubble:nth-child(3n){
    background: #b4914d;
  }
  .bubble:nth-child(1){
    width:40px;
    height:40px;
    left:10%;
    animation-duration:8s;
  }
  .bubble:nth-child(2){
    width:20px;
    height:20px;
    left:20%;
    animation-duration:5s;
    animation-delay:1s;
  }
  .bubble:nth-child(3){
    width:50px;
    height:50px;
    left:35%;
    animation-duration:7s;
    animation-delay:2s;
  }
  .bubble:nth-child(4){
    width:80px;
    height:80px;
    left:50%;
    animation-duration:11s;
    animation-delay:0s;
  }
  .bubble:nth-child(5){
    width:35px;
    height:35px;
    left:55%;
    animation-duration:6s;
    animation-delay:1s;
  }
  .bubble:nth-child(6){
    width:45px;
    height:45px;
    left:65%;
    animation-duration:8s;
    animation-delay:3s;
  }
  .bubble:nth-child(7){
    width:90px;
    height:90px;
    left:70%;
    animation-duration:12s;
    animation-delay:2s;
  }
  .bubble:nth-child(8){
    width:25px;
    height:25px;
    left:80%;
    animation-duration:6s;
    animation-delay:2s;
  }
  .bubble:nth-child(9){
    width:15px;
    height:15px;
    left:70%;
    animation-duration:5s;
    animation-delay:1s;
  }
  .bubble:nth-child(10){
    width:90px;
    height:90px;
    left:0%;
    animation-duration:10s;
    animation-delay:4s;
  }
  .bubble:nth-child(11){
    width:40px;
    height:40px;
    left:5%;
    animation-duration:10s;
    animation-delay:6s;
  }
  .bubble:nth-child(12){
    width:60px;
    height:60px;
    left:15%;
    animation-duration:10s;
    animation-delay:9s;
  }
  @keyframes rise{
    0%{
      bottom:-100px;
      transform:translateX(0);
    }
    50%{
      transform:translate(100px);
    }
    100%{
      bottom:1080px;
      transform:translateX(-200px);
      opacity: 0;
    }
  }