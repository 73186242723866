@import '../layout/Responsive.scss';
@import '../layout/Variables.scss';

#feelTheRush{
    padding-bottom: 6rem;

    @include size_L {
        padding-bottom: 0;
    }

    .feel_the_rush_image_holder {
        position: relative;
        background-color: $backgroundColor;

        img {
            width: 92%;

            @include size_S {
                width: 96%;
            }
        }

        .feel_the_rush_title {
            position: absolute;
            bottom: 10%;
            right: 16%;
            font-size: 4rem;
            font-weight: 600;
            color: white;
            transform: rotate(357deg);

            @include size_L {
                font-size: 3rem;
            }

            @include size_S {
                font-size: 2.5rem;
            }

            @include size_XS {
                font-size: 2rem;
            }

        }
    }
}