@import '../layout/Responsive.scss';
@import '../layout/Variables.scss';

#features{
    padding: 5rem 0;

    @include size_S {
        padding-top: 0;
    }
}
.features_section {
    max-width: 1180px;
    display: flex;
    align-items: flex-start;
    margin: auto;
    padding: 0 1rem;

    @include size_L {
        padding: 0 5px;
    }
    @include size_S {
        flex-direction: column-reverse;
    }

    .features_text_section, .features_image_holder {
        flex-basis: 50%;

        @include size_L {
            flex-basis: unset;
            width: 100%;
        }
    }

    .features_text_section {
        @include size_L{
            text-align: center;
        }
    }

    .features_image_holder {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;

            @include size_L {
                max-width: 500px;
            }
        }

        @include size_L {
            transform: rotate(90deg);
            margin-bottom: -50px;
        }
    }

    .features_section_title{
        font-size: 4rem;
        font-weight: 600;
        margin-bottom: 1rem;

        @include size_XS {
            font-size: 2.5rem;
        }
    }
    .features_section_subtitle{
        max-width: 600px;
        font-size: 1.2rem;
        margin-bottom: 5rem;
        color: #ffffff5e;
        line-height: 1.4;

        @include size_L{
            margin: auto;
            margin-bottom: 5rem;
        }
    }

    .features_grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2rem;
        width: fit-content;
        margin: auto;

        @include size_S {
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
        }

        .feature_box{
            background-color: #ffffff07;
            width: 160px;
            height: 130px;
            margin: auto;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 1rem 0 1.5rem 0;

            @include size_XS {
                width: 140px;
            }
        }

        .feature_box_title {
            text-transform: uppercase;
            color: #ffffff38;
            font-weight: bold;
            text-align: center;

            @include size_XS {
                font-size: 0.9rem;
            }
        }

        .feature_box_subtitle {
            font-size: 0.8rem;
        }

        .feature_box_value {
            font-size: 1.5rem;
            font-weight: bold;
            color: white;
        }
    }
}