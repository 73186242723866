@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");
@import './components/layout/Responsive.scss';
@import './components/layout/Variables.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  // font-family: "Open Sans", sans-serif;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-size: 1rem;
}

.section_title[data-title]::before{
  content: attr(data-title);
  position: absolute;
  left: -1.8rem;
  color: #272727;
  font-size: 14rem;
  line-height: 0.8;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Open Sans';
  z-index: -1;
  bottom: -20px;

  @include size_L {    
    font-size: 10rem;
  }
  @include size_M {    
    font-size: 6rem;
  }
  @include size_S {    
    font-size: 6rem;
  }
}

.section_title{
  font-size: 6rem !important;
  font-family: 'OracleScript';
  font-weight: unset !important;
  color: #76b6c4;
  letter-spacing: 5px;
  position: relative;

  @include size_L {    
    font-size: 4rem !important;
  }
  @include size_M {    
    font-size: 3rem !important;
  }
  @include size_S {    
    font-size: 3rem !important;
  }
}
#feedbackWindow{
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  z-index: 2;
  filter: opacity(.75);

  & > .feedback_message {
    padding: 2rem;
    border-radius: 5px;
  }

  & > .feedback_message:not(:last-of-type){
      margin-bottom: 0.6rem;
  }

  & .success_log{
    background: #45c273;
    box-shadow: 0 -2px 13px #45c273cf;
  }
  & .error_log{
    background: #d75a5a;
    box-shadow: 0 -2px 13px #d75a5ad4;
  }
}

.text_primary{
  color: $colorPrimary;
}

.text_bold{
  font-weight: bold;
}