.eu_body{
    display: block;
    background-color: white;
    color: black;
    width: 100%;
}

.eu_container{
    display: block;
    color: black;
    text-align: center;
    padding: 2rem;
    line-height: 1.5rem;
    max-width: 800px;
    margin: auto;
}

.eu_img1{
    background-image: url("../../images/EU/eu-flag.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 133.39px;
    width: 200px;
    margin: auto;
}

.eu_img2{
    background-image: url("../../images/EU/esi.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 65.84px;
    width: 200px;
    margin: auto;
    margin-top: 2rem;
}

.eu_img3{
    background-image: url("../../images/EU/opkk.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 53.66px;
    width: 200px;
    margin: auto;
    margin-top: 2rem;
}

.eu_description{
    padding: 1rem 0 1rem 0;
}

.eu_text{
    padding: 1rem 0 1rem 0;
}

.eu_paragraf{
    padding-bottom: 2rem;
}

.eu_paragraf2{
    padding-bottom: 1rem;
}

.eu_link{
    color: blue !important;
    transition: 250ms ease-in-out;
}

.eu_link:hover{
    color:orangered !important;
}