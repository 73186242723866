.navbar--menu--container {
  display: none;
}

#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 4rem;
  width: 100%;
  z-index: 10;
  transition: background-color 300ms ease-out;
}

.navbar--container {
  display: grid;
  grid-template-columns: 50% 50%;
  position: absolute;
  align-items: center;
  height: 100%;
  width: 90%;
  z-index: 9;
}

.navbar--logo--container {
  display: flex;
  height: 4rem;
  width: 100%;
}

.navbar--logo {
  display: flex;
  height: 4rem;
  width: initial;
}

.navbar--logo > img {
  display: flex;
  height: 4rem;
  width: initial;
  object-fit: contain;
  object-position: left;
  cursor: pointer;
  padding: 1rem 0;
  user-select: none;
}

.menu--container {
  display: grid;
  grid-template-rows: repeat(7, auto);
  background-color: transparent;
  position: fixed;
  top: -100vh;
  padding-top: 6rem;
  padding-bottom: 10rem;
  height: 100vh;
  width: 100%;
  list-style: none;
  transition: all 500ms ease-out;
  align-items: center;
  justify-content: center;
  color: transparent;
  overflow: auto;
  z-index: 9;
}

.open .menu--container {
  top: 0vh;
  color: #ffffff;
  background-color: #10151a;
}

.menu--container > li {
  cursor: pointer;
  box-sizing: content-box;
  font-size: 1.5rem;
  text-align: center;
  height: 100%;
}

#navbar--burger {
  display: block;
  justify-self: right;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 32px;
  height: 4px;
  background-color: #ffffff;
  margin: 8px 0;
  transition: all 500ms ease-out;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-8px, 8px);
  transform: rotate(-45deg) translate(-8px, 8px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-9px, -9px);
}

#toTop {
  background-color: #c7c7c7;
  background-image: url("../../assets/images/navbar/arrow.svg");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  display: none;
  position: fixed;
  bottom: 4rem;
  right: 2rem;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  z-index: 2;
  opacity: 20%;
  cursor: pointer;
  transition: 250ms ease-in-out;
}

.navbar_effect{
  background: rgba(0, 0, 0, 0.145);
  backdrop-filter: blur(10px);
}

@media screen and (max-width: 870px) and (min-width: 640px) {
  #toTop {
    bottom: 4rem;
    right: 4rem;
    height: 4rem;
    width: 4rem;
  }
}

@media screen and (min-width: 870px) {
  #navbar--burger {
    display: none;
  }

  #menu {
    display: none;
  }

  .navbar--container {
    display: grid;
    grid-template-columns: 30% 70%;
    position: absolute;
    height: 100%;
    width: 90%;
    z-index: 10;
  }
  .navbar--menu--container {
    display: block;
  }

  .navbar--menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: 2rem;
    color: #ffffff;
    position: absolute;
    top: 0;
    right: 0;
    text-align: center;
    justify-content: center;
    width: auto;
    z-index: 9;
  }

  .navbar--menu--item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.8rem;
    cursor: pointer;
    box-sizing: content-box;
    text-transform: uppercase;
    font-weight: 300;
    color: #ffffffdb;
  }

  .navbar--menu--item:hover {
    border-bottom: 0.2rem solid #75e0ff;
  }

  #toTop {
    bottom: 4rem;
    right: 4rem;
    height: 4rem;
    width: 4rem;
  }
}
