@import '../../../components/layout/Responsive.scss';

html,
body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

.shop {
  display: flex;
  min-height: 100%;
  max-width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
}

.shop--container {
  padding: 4rem;
  max-width: 70rem;

  @include size_M{
      padding: 3rem;
  }

  & h2{
    line-height: 1.2;

    @include size_M{
        font-size: 1.3rem;
    }
  }

  & p{
    line-height: 1.4;
    
    @include size_M{
        font-size: 1.2rem;
    }
  }
}

.heading {
  padding-bottom: 4rem;
}

.text {
  padding-top: 1rem;
  font-size: 1.5rem;
}

.footer {
  padding-top: 4rem;
  text-align: right;
}

.link {
  animation-name: catch;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  border-bottom: white solid 0.2rem;
}

@keyframes catch {
  from {
    color: #ffffff;
  }
  to {
    color: #20abfc;
  }
}
