@import '../layout/Responsive.scss';
@import '../layout/Variables.scss';

#home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  object-fit: contain;
}

.observedVisibility {
  opacity: 0;
}

.is-inViewport {
  transition-delay: 2s;
  transition: 500ms ease-in 250ms;
  opacity: 1;
}

#video {
  object-fit: cover;
  top: 0;
  height: 110%;
  width: 100%;
  position: fixed;
}

.home--container {
  display: block;
  position: relative;
  color: white;
  padding-top: 10rem;
  z-index: 1;
  user-select: none;
}

.home--container > h1 {
  font-size: 2.5rem;
}

.home--container > p {
  padding-top: 0.5rem;
  font-size: 1rem;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.overlay{
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: #0000003d;

  &.gradient{
    background: linear-gradient(#0000005c 50%, $backgroundColor 100%);
  }
}

#arrowDown{
  z-index: 1;
  position: absolute;
  bottom: 2rem;
  left: calc(50% - 15px);
  border: 2px solid #ffffff73;
  border-radius: 20px;
  height: 50px;
  width: 30px;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  color: #ffffff73;
  transition: all 0.1s ease;
  opacity: 0.75;
  cursor: pointer;

  &:hover{
    opacity: 1;
  }
}

.heroTitle{
  transform: rotate(-4deg);
  margin: 0 10%;
  img {
    max-width: 100%;
  }
}

.hero_moto{
  z-index: 2;
  position: absolute;
  left: -120px;
  bottom: 220px;
  text-transform: uppercase;
  color: #ffffff87;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 0.9rem;
  transform: rotate(-90deg);

  @include size_M{
    font-size: 0.7rem;
    bottom: 170px;
  }

  @include size_S {
    left: -150px
  }
}

.hero_social_icons{
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  right: 40px;
  align-items: center;
  bottom: 40px;
  color: #ffffff87;

  @include size_M{
    right: 20px;
    bottom: 30px;
  }
}

@media screen and (max-width: 870px) and (min-width: 650px) {
  .home--container {
    display: block;
    position: relative;
  }

  .home--container > h1 {
    font-size: 3rem;
  }

  .home--container > p {
    padding-top: 1rem;
    font-size: 1.25rem;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }
}

@media screen and (min-width: 870px) {
  .home--container {
    display: block;
    position: relative;
  }

  video {
    width: 100%;
  }

  .home--container > h1 {
    font-size: 6rem;
  }

  .home--container > p {
    padding-top: 1rem;
    font-size: 2rem;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
  }
}
