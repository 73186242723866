@import '../layout/Responsive.scss';

.eu_projects{
  cursor: pointer;
}

.footer_bull{
  font-family: Arial, Helvetica, sans-serif;
}

.footer_links{
  transition: 250ms ease-in-out;
}

.footer_links:hover{
  color: #666666;
}

#footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 10rem;
  position: relative;
  z-index: 1;

  @include size_L{
    padding: 0 7rem;
  }

  @include size_M{
    padding: 0 2rem;
  }
}

.visibility {
  opacity: 0;
}

.is-inViewport {
  transition-delay: 2s;
  transition: 500ms ease-in 250ms;
  opacity: 1;
}

.contact {
  padding: 4rem 2rem 4rem 2rem;
  width: 100%;
  color: #004a74;
}

.contact > h1 {
  font-size: 1.5rem;
}

.contact > p {
  font-size: 1rem;
}

.contact > ul {
  padding-top: 2rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  list-style: none;
  position: relative;
}

.footer--info {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.footer--info > ul {
  list-style-position: inside;
  list-style-type: none;
}

.footer_contact_box{
    background: #3c99b1;
    height: 5rem;
    display: flex;
    align-items: center;
    border-radius: 1000px;
    padding: 1rem 1rem 1rem 3rem;
    z-index: 6;
    margin-top: -2.5rem;
    box-shadow: 0 0px 2rem #35879ebd;
    justify-content: space-between;
    line-height: 1;
    width: 100%;
    max-width: 700px;
 
  @include size_XS{
    padding: 0;
    justify-content: center;
  }

  & .footer_contact_box_title{
    font-size: 1.5rem;
    color: white;

    @include size_XS{
        display: none;
    }
  }

  & .social_media_icon_box{
    display: flex;
    align-items: center;
    justify-content: center;

    & a{
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 7px;
        color: #eaeaea;
        background-color: #0000000d;
        font-size: 1.3rem;

        &:hover{
            background-color: #00000023;
        }
    }
  }
}
.logo_box {
    width: 100%;
    padding-bottom: 2rem;
    display: flex;

    & > img {
      width: 150px;
      user-select: none;
    }

    & .logo_box_text{
      display: flex;
      flex-direction: column;
      line-height: 1;
    }

    & .logo_box_title{
      font-size: 2rem;
      margin-left: 1rem;
      text-transform: uppercase;
      letter-spacing: 6px;
    }

    & .logo_box_subtitle{
      text-align: left;
      margin-left: 1rem;
      font-size: 2rem;
      letter-spacing: 5px;
      font-weight: 400;
      color: #76b6c4;
      font-family: 'OracleScript';
      letter-spacing: 6px;
    }
}

.footer_group_title_box{
  text-align: left;

  & .footer_group_title_box_text{
    font-size: 0.9rem;
    text-transform: uppercase;
    color: #737373;
    font-weight: bold;
    letter-spacing: 6px;
    margin-bottom: 1rem;
    font-weight: 600;
  }
}
.footer_group_content{
  text-align: left;
  line-height: 1.5;
  color: #dedede;
  font-weight: 300;
  font-size: 1.1rem;
  margin-top: 0.7rem;
}

.bull{
  font-family: 'Open Sans';
  margin: 0 6px;
}

.contact_container{
  display: flex;

  @include size_L{
    flex-direction: column;
  }
}

.contact_group{
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #d7d7d7;

  @include size_L{
    &:not(:last-of-type){
        margin-bottom: 1rem;
    }
  }
}
.google_map_container{
  width: 100%;
  max-width: 70rem;
}

.footer_content_box{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem 0;
  z-index: 1;
}

.footer--links {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem;
  z-index: 1;
}

.footer--links > div {
  text-align: center;
  flex-basis: 100%;
}

.footer--links > *:nth-child(1) > a > svg,
.footer--links > *:nth-child(2) > a > svg,
.footer--links > *:nth-child(3) > a > svg {
  height: 2rem;
  width: 2rem;
}

.footer--links > *:nth-child(1) > a,
.footer--links > *:nth-child(2) > a,
.footer--links > *:nth-child(3) > a {
  text-decoration: none;
  color: #fff;
  transition: 250ms ease-in-out;
}

.footer--links > *:nth-child(1) > a:hover,
.footer--links > *:nth-child(2) > a:hover,
.footer--links > *:nth-child(3) > a:hover {
  color: #7e7e7e;
}

.footer--bottom {
  display: flex;
  padding: 1rem 0;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  z-index: 1;
}

.map--container {
  height: 50vh;
}

@media screen and (max-width: 870px) and (min-width: 560px) {
  .contact {
    padding: 4rem 4rem 4rem 4rem;
    width: 100%;
    color: #004a74;
  }

  .contact > h1 {
    font-size: 1.75rem;
  }

  .contact > ul {
    padding-top: 2rem;
    padding-bottom: 1rem;
    font-size: 1.25rem;
    list-style: none;
    position: relative;
  }

  .footer--links {
    padding: 4rem;
  }

  .map--container {
    height: 80vh;
  }
}

@media screen and (min-width: 870px) {

  .contact {
    padding: 6rem 4rem 6rem 4rem;
    width: 48rem;
  }

  .contact > h1 {
    font-size: 2rem;
  }

  .contact > ul {
    padding-top: 2rem;
    font-size: 1.25rem;
    list-style: none;
    position: relative;
  }

  .footer--links {
    max-width: 78rem;
    padding: 4rem;
  }

  .map--container {
    height: 50vh;
  }
}