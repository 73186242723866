@import '../layout/Variables.scss';
@import '../layout/Responsive.scss';

#rentingAndTeamBuildings{
    overflow: hidden;

    img {
        width: 100%;

        @include size_M {
            width: 150%;
            margin-left: -25%;
        }
    }

    .renting_text_section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $backgroundColorDark;
        margin-top: -1rem;
        padding: 3rem 1rem;
        gap: 2rem;
        text-align: center;
    }

    .renting_text_section_title {
        font-size: 3rem;
        font-weight: 600;
        max-width: 700px;
        margin: auto;

        @include size_M {
            font-size: 2rem;
        }
    }
    .renting_text_section_content {
        font-size: 1.2rem;
        color: #ffffff5e;
        line-height: 1.4;
        max-width: 700px;
        margin: auto;
    }
}